.company_logo img{
    width:200px;
    height:100% !important;
    object-fit: cover;;
}

.appIcon{
    width:38px !important;
}

.laptop_screen_box{
    display: flex;
    justify-content: end;
}

.laptop_screen{
    width:80%;
    display: flex;
    justify-content: end;
    position: relative;
    /* bottom: 25px;; */
}

.logo img{
    width:170px !important;
}

.width50{
    width:50%;
}

.width70{
    width:70% !important;
}

.width80{
    width:80% !important;
}

.width90{
    width:90% !important;
}

.width60{
    width:60% !important;
}

.width40{
    width:40% !important;
}

.width10{
    width:10%;
}





/*  */

.height50{
    width:50%;
}

.height70{
    width:70% !important;
}

.height80{
    height:80% !important;
}

.height90{
    width:90% !important;
}

.height60{
    width:60% !important;
}

.height40{
    width:40% !important;
}

.height10{
    width:10%;
}
.height100{
    height: 100% !important;;
}

.product_services_img{
    height:160px !important;
    object-fit: cover;
    width:100%;
}

.industries_icon{
    width: 70%;
    position: absolute;
    transform: rotate(45deg);
    box-shadow: none !important;
}

.industries_icon2{
    width: 60%;
    position: absolute;
    transform: rotate(45deg);
    filter: invert(29%) sepia(45%) saturate(2832%) hue-rotate(227deg) brightness(90%) contrast(96%) !important;
    box-shadow: none !important;
}
.solution_menu {
    color: rgb(148 163 184 / 1) !important;
    /* padding-right:30px; */
    padding:0;
    box-shadow: none;
}

.solution_heading{
    position: relative;
    left:20px;
    padding-top:20px;
    border-bottom:1px solid rgb(148 163 184 / 1);
    width:84%;
    padding-bottom:10px;
}

.align-items-center{
    align-items: center;
}



.solution_sub_menu ul li{
    display: flex !important;
}

.appIcon_box{
    background:#e1e1e12e;
    width:70px;
    height:70px;
    border-radius:50%;
    padding:5px;
}

.solution_box{
    display: flex;
    justify-content: space-between;
    width:100%;
}

.radius-4{
    border-radius: 4px;;
}

.radius-12{
    border-radius: 12px;;
}

.solution_menu_box{
    width: 850px;
    visibility: hidden;
    opacity: 0;
    background:white;;
    padding:20px;
    position:absolute;
    right:30%;
    top:110%;
    display: flex;
    border-radius:4px;
    transition:0.3s;
    justify-content: space-between;
    left:-81%;
}

.solution_hover_menu:hover .solution_menu_box{
    visibility: visible;
    opacity: 1;;
    top:100%;

}

.solution_menu_box > div{
    width:calc(25% - 15px);
    padding:2px;
    /* border-bottom:1px solid black; */
}

.d-flex{
    display: flex;
}

.justify-content-end{
    justify-content: end;
}

.solution_menu_box ul{
    display: block !important;
}

.justify-content-center{
    justify-content: center;
}

.justify-content-between{
    justify-content: space-between;
}

.solution_menu_box ul li a{
    transition: all 0.3s;
    clear: both;
    display: block;
    white-space: nowrap;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 17px;
    letter-spacing: 0.04em;
    --tw-text-opacity: 1;
    color: rgb(22 28 45 / var(--tw-text-opacity));
}

.solution_menu_box ul li a:hover{
    color:rgb(79 70 229);
}

.megamenu-head{
    color:rgb(184, 184, 184) !important;
    position: relative;
    left:22px;
}

.solution_sub_menu{
    position: absolute;
    background: white;
    /* right: 0; */
    display: none;
    /* visibility: hidden; */
    padding: 5px;
    border-radius: 4px;
    transition:0.5s;
    left: 20%;
}

.solution_sub_menu_hover:hover > div {
    display: block !important;
    /* visibility: visible; */
    transition:0.5s;

}

.pricing_plan_man{
    width:800px;
    /* background:red; */
    margin:auto;
    border-radius:8px;
    padding:40px;
}

.pricing_box_3{
    width:600px;
    /* background:red !important; */
    border-radius:8px;
    display:flex;
    align-items: center;
    margin:auto;
    padding:20px;
    justify-content: center;
    flex-flow: column;
}

.pricing_plan_man div:nth-child(1){
    width:40%;
    /* background:green; */
}

.pricing_plan_man div:nth-child(2){
    width:60%;
    /* background:red; */
}

.text-decoration-line-through{
    text-decoration: line-through;
}

.prices{
    position: relative;
    top:7px;
    right:13px;
}

.width20{
    width:20%;
}

.width30{
    width:25%;
}

.width18{
    width: 15% !important;;
}

.width15{
    width:12% !important;
}

.heading{
    font-size:43px !important;
}

.customer_img{
    width:100%;
    height: 240px;;
    object-fit: cover;
}

.cursor-pointer{
    cursor: pointer !important;
}
.price_man_box{
    display: flex;
    /* background:red; */
    justify-content: center !important;
}

.price_man_box > div{
    width:500px;
}

.client_logo{
    width:80% !important;
    object-fit: contain;
    /* height: 100px;; */
    mix-blend-mode: color-burn !important;
    aspect-ratio: 3/2;
    height: 60px;
    padding:0px 10px !important;
    /* filter: grayscale(100%); */
    border: 1px solid rgb(221, 221, 221);
    transition: 0.5s;;
}

.client_logo:hover{
    filter: grayscale(0%);
}

.medium_size{
    width:80% !important;
    position: relative;
    /* top:10px; */
    transition: 0.5s;;

}

.medium_size:hover{
    filter: grayscale(0%);

}


.pricing_feature_box:hover svg{
    fill:white !important;; 

}

.appIcon_box svg{
    color:red !important;
    fill: #4f46e5; 
}

.help_center_icon svg{
    fill: #4f46e5; 
    width:35px;
}

.swiper-pagination{
    /* display:none !important; */
}

.swiper-pagination-bullet{
    width:13px;
    opacity: 1;
    height: 13px;
    background-color: #979797;;
}

.swiper-pagination-bullet-active{
    background:white !important;
}
.visiblity-hidden{
    visibility: hidden;
}

.border-right{
    border-right:1.5px solid rgb(228, 228, 228);
    padding-right:20px;
}

.choose_us_img{
    height: 200px;;
    width:100%;
    object-fit: cover;;
}

.videokrypt_banner{
    background:#fbfbfd;
}

.check_icon{
    width:23px;
    height:23px;
    background:#4f46e5;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size:14px;
    margin-right: 10px;;
    color:white;
    border-radius:50%;
}


.pos_img{
    height:100%;
    object-fit: cover;;
}

.hardware_box_font{
    font-size: 15px !important;;
}

.hardware_icon{
    width:160px;
    height: 70px;
    object-fit: contain;
}

.border-top {
    border-top:1px solid #cfcfcf;
}

.border-bottom{
    border-bottom:1px solid #cfcfcf;
}

.hover-gray:hover{
    background:#b4b4b4 !important;
}

.hardware_icon2{
    filter: invert(37%) sepia(77%) saturate(2912%) hue-rotate(359deg) brightness(100%) contrast(99%);

    width: 45px !important;
    
}










/* ============== Responsive Here Start=============== */

@media(max-width:1280px){
    .solution_menu_box > div {
        width: calc(50% - 15px);
        padding: 2px;
        /* border-bottom: 1px solid black; */
    }
}
@media(max-width:1035px){
   .md-mb-2{
    margin-bottom: 20px;;
   }
}


@media (max-width: 991px) {
    #navigation {
        position: absolute;
        top: 74px;
        inset-inline-start: 0px;
        display: none;
        height: auto;
        width: 100%;
        padding-bottom: 20px;
        overflow: auto;
        --tw-bg-opacity: 1;
        background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    }

    .header-button{
        left:-30px;
    }

    .header-button a{
        position: relative;
        left:-5px !important;;
        /* background:red !important; */
    }
}
@media(max-width:548px){
    .heading{
        font-size:26px !important;
    }

    .text-slate-400{
        font-size: 17px !important;;
    }




}
/* ============== Responsive Here End =============== */




/* Get Started code */

.form-control {
    padding: 0.8rem 1.1rem;
}

label {
    display: inline-block;
    margin-bottom: 0.5rem;
}
.form-control {
    display: block;
    width: 100%;
    height: calc(1.2em + 1.6rem + 2px);
    padding: 0.8rem 1.6rem;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.2;
    color: #131e3f;
    background-color: #ffffff;
    background-clip: padding-box;
    outline: none;;
    border: 1px solid #f2f3f7;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.get-started-textarea{
    height:80px;
    outline:none;
    border: 1px solid #f2f3f7;
    color: #131e3f;
    background-color: #ffffff;
    width:100%;
    border-radius: 0.25rem;

    padding: 0.8rem 1.6rem;

}

.bg-indigo-600{
    /* background:#fc5203 !important; */
}

.bg-indigo-600:hover{
    /* background:#fc5203 !important; */
}

.border-indigo-600{
    /* border:1px solid #fc5203 !important; */
}

.border-indigo-600:hover{
    /* border:1px solid #fc5203 !important; */
}

.hover:text-indigo-600{
    /* color:#fc5203 !important; */
}

/* .text-lg:hover{
    color:#0e015d !important;
    transition:0.5s;
} */

.text-indigo-600{
    /* color:#fc5203 !important; */
}

.icon-orange{
filter: invert(37%) sepia(77%) saturate(2912%) hue-rotate(359deg) brightness(100%) contrast(99%);
}

.text-xl:hover{
    /* color:#fc5203 !important; */
}

.footer-list ul li{
    position:relative !important;
    right:100px !important;
}

.mr-1{
    position: relative;
    right:4px !important;;
}

.svg-top{
    position:relative;
    /* color:#fc5203 !important; */
    top:-8px;
    margin-right:10px;
}

body,.navbar-color-orange ul li a{
    /* color:red !important; */
}

#topnav .navigation-menu > li:hover > a, #topnav .navigation-menu > li > a.active {
    /* color:#fc5203 !important; */
    
}

#topnav .navigation-menu > li:hover > .menu-arrow {
    /* color:#fc5203 !important; */
    /* border-color: #fc5203 !important; */

}

#topnav .navigation-menu > li .submenu li a:hover {
    /* color:#fc5203 !important; */
  
}

#topnav .navigation-menu > li.active > a {
    --tw-text-opacity: 1 !important;
    /* color:#fc5203 !important; */

}

#topnav .has-submenu.active.active .menu-arrow {
    /* border-color:#fc5203; */
}

#topnav .navigation-menu > li > a {
    /* color:#fc5203 !important; */

}

#topnav .navigation-menu .has-submenu .menu-arrow {
    /* border-color:#fc5203; */
 
}


/* whatshap detail css start */
.whatshapp_img {
    position: fixed;
    right: 0;
    bottom: 0;
    background: white;
    z-index: 100000;
    padding: 8px 0px;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 0px 0px, rgba(14, 30, 37, 0.32) 0px 2px 9px 0px;
    overflow: hidden;
  }
  
  .whatshapp_img ul li a {
    color: black !important;
    font: normal 600 14px "Rubik", sans-serif;;
    padding: 12px 15px;
  }
  
  .whatshapp_img ul li:nth-child(2) a {
    border-left: 1px solid black !important;
    padding: 3px 15px;
    /* font-size: 17px; */
    position: relative;
  }
  
  .whatshapp_img ul li:last-child a {
    background: var(--blue-color);
    color: #fff;
    /* font-size: 17px; */
    padding: 11px 15px;
  }
  
 
  
  .whatshapp_img ul li:nth-child(2) a:after {
    position: absolute;
    left: -6px;
    top: -1px;
    content: '';
    background: #fc5203;
    z-index: 100000;
    border:1px solid white;
    border-radius: 50%;
    height: 10px;
    width: 10px;
    animation: shadow infinite 2s;;

  }

  @keyframes shadow {
    50% {box-shadow: 0px 0px 4px 4px  #fc5203;;border:2px solid white;}
}

  .whatshapp_img i{
    color:#fc5203;
  }

  .whatshapp_img a{
    text-decoration: none;
  }
  
  /* whatshap detail css end */



  /* topbar */
  .topbar {
    position: fixed;
    top: 0;
    padding-right: 20px;
    width: 100%;
    padding: 15px;
    background: #ffffffbd;
    z-index: 1000;
}
  #topnav {
    position: fixed;
    inset-inline-start: 0px;
    inset-inline-end: 0px;
    /* top: 50px !important; */
    z-index: 999;
    border-width: 0px;
    background-color: transparent;
    transition-duration: 500ms;
}

#topnav.nav-sticky {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    top: 0 !important;
    --tw-shadow: 0 0 3px rgb(60 72 88 / 0.15);
    --tw-shadow-colored: 0 0 3px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.text-orange{
    color:#fc5203 !important;;
}

.text-black{
    color:black !important;
}

.header-button{
    position:relative;
    top:14px;
    left:30px;
}

.height-300{
    height: 300px !important;;
}

.icon-blue{
    filter: invert(30%) sepia(56%) saturate(7024%) hue-rotate(239deg) brightness(93%) contrast(93%);


}

.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #000000c2;
    display: none;
    align-items: center;
    top: 0;
    transition: 0.5s;;
    left: 0;
    z-index: 1000000;
}


.popup input {
    border-radius: 6px !important;
    border-bottom: 1.4px solid #4f46e5;
}

.popup select{
    border-radius:6px !important;
    border-bottom: 1.4px  solid #4f46e5;
}
.popup_flex{
    /* width:800px; */
    height: 600px;
    padding:20px;
    background:white;
    margin:auto;
    border-radius:12px;
}

.tns-controls{
    display: none !important;
}

.crose {
    position: absolute;
    width: 16px;
    cursor: pointer;
    top: 3%;
    right: 3%;
}

/* .text-slate-400 {
    color:black !important;
} */

.book_demo_form{
    height: 100vh;
    overflow: auto;
}

::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #4f46e5;;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  html,body{
    overflow-x: hidden;
  }




  .text-blue{
    color:#3e50f7
}


.no-repeat{
    background-repeat: no-repeat;
    width:100%;
    background-size: cover;
}



/* Dropdown Design */

.what-we-do-drop{
    position: absolute;
    opacity: 0;
    width: 100%;
    left: 0%;
    padding: 30px 30px 50px 30px;
    background: #0f172a;
    /* height: 100vh; */
    display: none;
    transition: 0.5s !important;;
    border-radius: 4px;
}

.what-we-do-menu-hover{
    transition: 0.5s;;
}

.what-we-do-menu-hover:hover .what-we-do-drop{
    display: block !important;
    opacity: 1;

}

.what-we-do-drop ul li a {
    transition: all 0.3s;
    clear: both;
    display: block;
    white-space: nowrap;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    font-size: 11px;
    font-weight: 700;
    text-transform: math-auto !important;

    
    line-height: 17px;
    letter-spacing: 0.04em;
    --tw-text-opacity: 1;
    color: white !important;
}

.what-we-do-drop ul li a:hover{
    color: rgb(79 70 229 / 1) !important;
}

.globe{
    width: 99px;
    height: 71px;
    display: flex !important;
    align-items: center;
    left:125px;
}

.globe img{
    width:20px;
    height: 20px;;
    
}

.who-we-are-social{
    margin-left: 20px;;
    margin-top: 30px;;
    position: relative;
    top:20px;
}

.who-we-are-social li{
    padding:5px 10px;
    /* background:#0e2354; */
    border-radius:2px;
    transition:0.5s;
}

.who-we-are-social li:hover{
    cursor: pointer;
}

#topnav .navigation-menu.nav-light > li > a{
    color: white !important;;
    transition: 0.5s;;
}

#topnav .navigation-menu.nav-light > li a:hover{
    color:#3e50f1 !important;

}

#topnav .navigation-menu > li > a{
    text-transform: math-auto !important;
}

#topnav .navigation-menu > li .submenu li a{
    text-transform: math-auto !important;
}


.christmas-boxes{
    position: absolute;
    z-index: 100000 !important;
    top:15%;
    left:37%;
}

.fill-indigo-600\/5 {
    fill: rgb(221 221 221) !important;
}

.flex-flow-column{
    flex-flow: column;
}

.border-top-blue{
    border-top:3px solid #4f46e5;
}

.banner-image{
    width: 138% !important;
    background-size: cover;
    background-position: center;
    object-fit: cover;
    border-radius: 12px;
    /* border: 11px solid white !important; */
}


.about-circule{
    position: absolute;
    background-color: #282f5d;
    border-radius:50%;
    width:400px;
    height:400px;
}

.clients-logo{
    width:150px !important;
    aspect-ratio: 1 /1;
    object-fit: contain;
    -webkit-filter: grayscale(100%);
    filter: grayscale(1);
    /* background:white; */
    border-radius: 4px;;
    transition: 0.5s;
    height: 60px !important;;
    mix-blend-mode: color-burn;
}

.clients-logo:hover{
    filter: grayscale(0);
    transition: 0.5s;;


}

.logo-gray{
    filter: invert(65%) sepia(12%) saturate(381%) hue-rotate(169deg) brightness(92%) contrast(80%);
    transition: 0.5s !important;
}

.logo-gray:hover{
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(212deg) brightness(102%) contrast(103%);
    transition: 0.5s !important;;
}

.slider-image-size{
    height: 400px;
    object-fit: cover;;
    width:100%;;
}

.top-5{
    top:5px;
}

.footer-logo{
    width:104px;
}

.radius-8{
    border-radius: 4px;;
}

.footer-logo{
    width:140px;
}

.width-15{
    width:15%;
}

.border-gray{
    border:1px solid #434343 !important;

}

.products-logo{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
}
.products-logo div{
    height: 70px;
    border-radius: 4px;;
    width:80%;
    background:white;
}

.products-logo div img{
    width:100%;
    height: 100%;
    object-fit: contain;

}


.products-logo2{
    display: flex;
    flex-flow: column;
}
.products-logo2 div{
    height: 70px;
    border-radius: 4px;;
    width:60%;
    background:white;
    border:1px solid #e9e9e9;
    display: flex;
    align-items: center;
    justify-content: center;
}

.products-logo2 div img{
    width:140px;
    height: 60px;;
    object-fit: contain;

}

.dark.copyright{
    color:red !important;
}

.privacy-policy-text{
    font-size: 14px;
    font-weight: 300;
}